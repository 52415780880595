#scopelink {
	@include section;
	background-color: $light-background-color;

	> header {
		margin-top: 96px;
		max-width: 560px;
	}

	> header > h1 {
		@include font($whitney, $size: 24px, $weight: 400);
		color: $dark-text-color;
		line-height: 1.25;
		text-align: center;

		@include breakpoint($normal) {
			font-size: 30px;
		}
	}

	> header > pre > p,
	> header > p {
		@include bodytext($weight: 100);
		text-align: center;
		margin-top: 16px;
	}

	> .content {
		@include section-content;
		@include column($align: center);
		margin-top: 64px;
	}
}
