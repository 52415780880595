#docs {
	@include section;

    > h1 {
		@include font($whitney, $size: 26px, $weight: 100);
		color: $light-text-color;
		line-height: 1.1;
		text-align: center;
		margin-top: 40px;

		@include breakpoint($large) {
			font-size: 30px;
		}
	}

	> h2 {
		@include font($whitney, $size: 19px, $weight: 100);
		color: $light-text-color;
		margin-top: 40px;
	}

	> p {
		@include bodytext;
		text-align: center;
		margin-top: 24px;
		width: 40vw;
	}

	> a {
		@include font($whitney, $size: 19px, $weight: 100);
		color: $green;
		margin-top: 40px;
	}
}