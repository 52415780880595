.blockquote {
	margin: 0 auto;
	width: 100%;
	max-width: 650px;

	> h1 {
		@include font($whitney, $size: 24px, $weight: 400);
		line-height: 1.25;
		text-align: center;

		@include breakpoint($large) {
			font-size: 30px;
		}
	}

	> p {
		@include bodytext($weight: 100);
		text-align: center;
		margin-top: 32px;
	}

	> footer {
		@include bodytext($weight: 100);
		text-align: center;
		margin-top: 32px;
	}

	> footer > cite {
		font-style: italic;
	}
}
