#video {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $light-background-color;
	color: $dark-text-color;
	padding: 24px;
	gap: 64px;

	@include breakpoint($large) {
		padding: 64px 24px;
	}
}

.video {
	display: grid;
	gap: 48px;

	@include breakpoint($normal) {
		max-width: 1050px;
		grid-template-columns: 1.5fr 1fr;
		align-items: center;
	}

	> iframe {
		width: 100%;
		aspect-ratio: 16/9;
	}
}
