#reminder {
    @include column($align: center, $justify: center);
	position: relative;
	padding: 32px 24px;
	height: calc(100vh - 80px);
	min-height: 720px;
	max-height: 860px;

	> h1 {
		@include font($whitney, $weight: 100);
		color: $light-text-color;
		line-height: 1.1;
		text-align: center;
		max-width: 624px;

		@include breakpoint($smallest) {
			font-size: 30px;
		}

		@include breakpoint($large) {
			font-size: 40px;
		}
	}

	> p {
		@include bodytext;
		text-align: center;
		margin-top: 24px;
		max-width: 480px;
	}

	> hedge-button,
	> .reminder__action {
		margin-top: 48px;
	}
}
