#pricing {
	@include section;
	background-color: $light-background-color;

	> header {
		margin-top: 96px;
		max-width: 560px;
	}

	> header > h1 {
		@include font($whitney, $size: 24px, $weight: 400);
		line-height: 1.25;
		color: $dark-text-color;
		text-align: center;

		@include breakpoint($normal) {
			font-size: 30px;
		}
	}

	> header > pre > p,
	> header > p {
		@include bodytext($weight: 100);
		text-align: center;
		margin-top: 16px;
	}

	> .content {
		@include section-content;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 64px;
		max-width: 315px;
	}

	> .content > .price-options {
		margin-bottom: 64px;
		overflow: hidden;

		@include breakpoint($smallest) {
            @include column;
		}

		@include breakpoint($normal) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 64px;
		}
	}

	> pre > footer,
	> footer {
		display: flex;
		align-items: center;
		flex-direction: column;
		@include bodytext($weight: 100);
		text-align: center;
		gap: 12px;
		max-width: 330px;
	}

	> footer > a:hover {
		color: $dark-text-color;
	}

	> small {
		@include bodytext($weight: 100);
		text-align: center;
	}
}

.price-option {
	@include bodytext($weight: 100);
    padding: 10px;
	position: relative;

	// The border between grid elements
	&::after {
		content: '';
		position: absolute;
		background-color: rgba(#1b1d22, 5%);
		z-index: 1;
		inline-size: 100%;
		block-size: 1px;
		inset-inline-start: 0;
		inset-block-start: -1px;
	}

    > i {
		margin-right: 16px;
		color: $green;
	}

	> i {
		color: $default-text-color;
	}

	> i.primary {
		color: $green;
	}

	> i.secondary {
		color: $blue;
	}
}
