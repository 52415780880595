hr {
	width: 100%;
	height: 1px;
	border: none;
	border-bottom: 1px solid $stroke-color;

	@include breakpoint($normal) {
		max-width: 696px;
	}

	@include breakpoint($large) {
		max-width: 908px;
	}
}

#concepts {
	@include section;
	padding: 64px 24px 128px !important;

	> .content {
		@include section-content;
		display: flex;
		flex-direction: column;
		// margin-top: 96px;

		@include breakpoint($large) {
			max-width: 764px;
		}
	}

	> .content > .concept:not(:first-of-type) {
		margin-top: 64px;
	}
}

.concept {
	display: flex;
	flex-direction: column;

	@include breakpoint($normal) {
		display: grid;
		grid-template-columns: min-content 1fr;
		grid-template-areas: "logo title" "logo description";
		column-gap: 24px;
	}

	&:not(:first-of-type) {
		margin-top: 48px;
	}

	> h1 {
		@include font($whitney, $size: 26px, $weight: 100);
		color: $light-text-color;
		text-align: center;
		line-height: 1.1;
		max-width: 420px;

		@include breakpoint($normal) {
			grid-area: title;
			text-align: left;
		}
	}

	> p {
		@include bodytext();
		text-align: center;
		margin-top: 12px;
		max-width: 640px;

		@include breakpoint($normal) {
			grid-area: description;
			text-align: left;
		}
	}

	> img {
		display: none;

		@include breakpoint($normal) {
			grid-area: logo;
			display: block;
			align-self: center;
			width: 75px;
		}
	}
}
