#footer {
    @include row($align: center, $justify: space-between);
	padding: 24px;

	> span {
		@include font($whitney, $size: 15px);
		color: $default-text-color;
		white-space: nowrap;
	}

	> ul {
        @include row;
		@include font($whitney, $size: 15px);
		color: $default-text-color;
	}

	> ul > li:not(:first-of-type) {
		margin-left: 24px;
	}

	> ul > li:nth-last-child(n + 2) {
		display: none;

		@include breakpoint($large) {
			display: block;
		}
	}

	> ul > li > a {
		color: inherit;
		text-decoration: none;
		white-space: nowrap;

		&:hover {
			color: $light-text-color !important;
		}
	}
}
