#billboard {
    @include column($align: center, $justify: center);
	position: relative;
	padding: 60px 24px 36px;

	@include breakpoint($normal) {
		padding: 120px 24px 16px;
	}

	> header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 32px;
		order: 2;
		gap: 16px;
	}

	> header > .logo {
		width: 150px;
		height: 40px;

		@include breakpoint($normal) {
			width: 200px;
			height: 53px;
		}
	}

	> header > h1 {
		@include font($whitney, $size: 30px, $weight: 100);
		color: $light-text-color;
		line-height: 1.25;
		text-align: center;
		max-width: 540px;

		@include breakpoint($large) {
			font-size: 40px;
		}
	}

	> header > h2 {
		@include font($whitney, $size: 20px, $weight: 100);
		color: $light-text-color;
		line-height: 1.25;
		text-align: center;
		max-width: 540px;

		@include breakpoint($large) {
			font-size: 40px;
		}
	}

	> header > p {
		@include bodytext;
		text-align: center;
		margin-top: 12px;
		max-width: 480px;
	}

	> header > span {
		@include bodytext;
		display: block;
		text-align: center;
		margin-top: 12px;
		max-width: 540px;
	}

	> img {
		@include size(192px);
		order: 1;
	}

	> hedge-button,
	> .billboard__action {
		margin-top: 48px;
		order: 3;
	}

	> footer {
		@include bodytext(14px);
		text-align: center;
		display: block;
		margin-top: 16px;
		max-width: 540px;
		order: 4;
	}
}
