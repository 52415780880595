#header {
	@include sticky($top: 0);
    @include row($align: center, $justify: space-between);
	padding: 24px;
	background-color: rgba($background-color, 0.9);
	z-index: 9;

	@supports (backdrop-filter: blur(4px)) {
		backdrop-filter: blur(4px);
	}

	> hgroup {
        @include row($align: center);
		flex: none;
	}

	> hgroup > h1 {
		@include size(110px, 26px);
		@include text-replacement;
		background-image: url("../images/hedge_logo.png");
		background-size: 100%;
		flex: none;
	}

	.whatsnew {
		@include font($whitney, $size: 17px, $weight: 100);
		color: $default-text-color;
		white-space: nowrap;

		display: none;
		margin: 0 auto 0 20px;

		@include breakpoint($large) {
			display: block;
		}
	}

	.whatsnew > a {
		color: $blue;
		text-decoration: none;

		&:hover {
			color: $light-text-color !important;
		}
	}
}
