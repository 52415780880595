@import 'hedge/support.scss';
@import 'hedge/views.scss';

body {
	display: flex;
	flex-direction: column;
	background-color: $background-color;
}

.feature-name {
	font-style: italic;
}