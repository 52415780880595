.banner {
    @include bodytext;
    text-align: center;
    padding: 30px 10px;
	background-color: white;

	@include breakpoint($large) {
        padding: 40px 75px;
    }
}
