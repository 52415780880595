@mixin section {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	@include breakpoint($smallest) {
		padding: 32px 24px 96px;
	}

	@include breakpoint($large) {
		padding: 32px 24px 128px;
	}
}

@mixin section-content {
	width: 100%;
	max-width: 300px;

	@include breakpoint($normal) {
		max-width: 696px;
	}

	@include breakpoint($large) {
		max-width: 908px;
	}
}

@mixin section-title {
	@include font($whitney, $size: 17px);
	color: $dimmed-text-color;
	text-align: center;

	padding-bottom: 12px;
	width: 100%;
	max-width: 300px;
	border-bottom: 1px solid $stroke-color;

	@include breakpoint($normal) {
		max-width: 696px;
	}

	@include breakpoint($large) {
		max-width: 908px;
	}
}

@mixin bodytext($size: 17px, $weight: 400) {
	@include font($whitney, $size: $size, $weight: $weight);
	color: $default-text-color;
	line-height: 1.5;

	a {
		color: $blue;
		text-decoration: none;

		&:hover {
			color: $light-text-color;
		}
	}
}
