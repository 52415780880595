#nle {
	@include section;
	padding: 0 24px 96px !important;

	h1 {
		@include font($whitney, $size: 24px, $weight: 400);
		line-height: 1.25;
		color: $light-text-color;
		text-align: center;
		margin-top: 48px;

		@include breakpoint($normal) {
			font-size: 30px;
		}
	}

	> img {
		width: 400px;
		order: 1;
	}

	.camera-icons {
		width: 100%;
		max-width: 980px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		> img {
			margin: 10px 20px;
			height: 28px;

			@media (min-width: 890px) {
				height: unset;
				margin: 10px 25px;
			}
		}
	}
}
