@import "hedge/support.scss";

#benefits {
	@include section;
	padding: 32px 24px 96px !important;

	hedge-button {
		margin-top: 32px;
		order: 3;
	}

	> h1 {
		@include font($whitney, $size: 24px, $weight: 400);
		line-height: 1.25;
		color: $light-text-color;
		text-align: center;

		@include breakpoint($normal) {
			font-size: 30px;
		}
	}

	> p {
		@include bodytext($weight: 100);
		text-align: center;
		max-width: 400px;
		margin-top: 18px;
	}

	> .content {
		@include section-content;
		margin-top: 64px;

		@include breakpoint($smallest) {
			@include column;
			gap: 48px;
		}

		@include breakpoint($normal) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 96px 48px;
		}

		@include breakpoint($large) {
			column-gap: 64px;
		}
	}
}

.benefit {
	@include column;

	> h1 {
		@include font($whitney, $size: 26px, $weight: 100);
		color: $light-text-color;
		line-height: 1.1;
		text-align: center;

		@include breakpoint($large) {
			font-size: 30px;
		}
	}

	> p {
		@include bodytext();
		text-align: center;
		margin-top: 24px;
	}

	> img {
		width: 100%;
		order: 1;
		border-radius: 4px;
	}

	> a {
		order: 4;
		align-self: center;

		@include breakpoint($large) {
			align-self: flex-start;
		}
	}
}
