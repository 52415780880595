.divider {
    @include size(100%, 1px);
    margin: 0 auto;
	max-width: 300px;
    border: 0;
    background-color: $stroke-color;

    &.light {
        opacity: 0.1;
    }

	@include breakpoint($normal) {
		max-width: 696px;
	}

	@include breakpoint($large) {
		max-width: 908px;
	}
}