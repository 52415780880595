#navigation {
	> ol {
        @include row;
	}

	> ol > li {
		@include font($whitney, $size: 17px, $weight: 100);
		color: $default-text-color;
		position: relative;
	}

	> ol > li:not(:first-of-type) {
		margin-left: 24px;
	}

	> ol > li:nth-last-child(n + 3) {
		display: none;

		@include breakpoint($normal) {
			display: block;
		}
	}

	> ol > li > a {
		color: inherit;
		text-decoration: none;
	}

	> ol > li:hover {
		color: $light-text-color;
	}

	> ol > li > a.selected {
		color: $light-text-color !important;
	}

	> ol > li > .download {
		color: $green;
		cursor: pointer;
	}

	> ol > li > a.pricing {
		color: $blue;
	}

	> ol > li > a.current {
		color: $light-text-color;
	}

	// submenu
	> ol > li > ol {
		display: none;
	}

	> ol > li:hover > ol {
		display: block;
		animation: navigation-present-submenu 350ms
			cubic-bezier(0.34, 1.56, 0.64, 1);
	}
}

// pulldowns
#navigation ol > li > ol {
	@include absolute($top: calc(100% + 10px), $left: -15px);
	padding: 20px 60px 20px 20px;
	box-shadow: 10px 10px 15px -5px rgba(0, 0, 0, 0.1);
	background-color: rgba($light-background-color, 0.97);
	border-radius: 5px;
	backface-visibility: hidden;

	&:before {
		@include absolute($top: -10px, $left: -20px);
		@include size(calc(100% + 40px));
		z-index: -1;
		content: "";
	}

	&:after {
		@include absolute($bottom: 100%, $left: 40px);
		@include size(0);
		content: " ";
		margin-left: -5px;
		border: solid transparent;
		border-bottom-color: rgba($light-background-color, 0.97);
		border-width: 5px;
	}

	@supports (backdrop-filter: blur(4px)) {
		backdrop-filter: blur(4px);
	}

	> li:not(:last-of-type) {
		padding: 0 0 15px;
	}

	> li > a {
		@include font($whitney, $size: 15px, $weight: 100);
		display: block;
		text-decoration: none;
		line-height: 1.4;
	}

	> li > a > .product {
		display: block;
		color: $dark-text-color;
		font-weight: 400;
		white-space: nowrap;
	}

	> li > a > .product-description {
		font-size: 14px;
		display: block;
		color: $default-text-color;
		white-space: nowrap;
	}

	> li > a:hover > .product {
		color: $blue;
	}

	> li > a:hover > .product-description {
		color: $blue;
	}
}

@keyframes navigation-present-submenu {
	from {
		opacity: 0;
		transform: translateY(-15px);
	}

	to {
		opacity: 1;
		transform: none;
	}
}
